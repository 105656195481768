
import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import ConceptImageSizes from "./ConceptsImageSizes.vue";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import { mapGetters } from "vuex";

interface OpeningHoursDataObject {
  day: string;
  open: string;
  close: string;
}

const ConceptPricesProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [translations],
})
export default class ConceptPrices extends ConceptPricesProps {
  translate!: Function;
  public getLocale: any;

  isLTR() {
    return this.getLocale === 'ar_SA' ? 'ltr' : '';
  }
}
